import {Component, Renderer2} from "@angular/core";
import {DateTime} from 'luxon';
import {Meta, Title} from "@angular/platform-browser";
@Component({
    selector: 'app-physiotherapy',
    templateUrl: './physiotherapy.component.html',
    styleUrls: ['./physiotherapy.component.scss'],
})

export class PhysiotherapyComponent {
    public currentYear: string = DateTime.now().toFormat('y');

    constructor(
        private meta: Meta, private titleService: Title, private renderer: Renderer2
    ) {

        this.titleService.setTitle('Best Physiotherapy Clinic in Trichy | Physiotherapy Clinic in Trichy');
        this.meta.addTag({
            name: 'description',
            content: 'Revitalize your well-being at Trichy\'s premier Physiotherapy Clinic. Expert care, personalized sessions, and a path to optimal mobility. '
        });
        this.meta.addTag({
            name: 'keywords',
            content: 'best physiotherapy clinic in trichy, physiotherapy clinic in trichy, best multi speciality hospital in trichy, trichy multi speciality hospitals, multi speciality hospitals in trichy, multi speciality hospital in trichy'
        });
    }

    ngOnInit() {
        this.addCanonicalLink();
    }

    ngOnDestroy() {
        this.removeCanonicalLink();
    }

    private addCanonicalLink() {
        const link = this.renderer.createElement('link');
        this.renderer.setAttribute(link, 'rel', 'canonical');
        this.renderer.setAttribute(link, 'href', 'https://varshinihospital.com/best-physiotherapy-doctor-trichy');
        this.renderer.appendChild(document.head, link);
    }

    private removeCanonicalLink() {
        const link = document.head.querySelector('link[rel="canonical"]');
        if (link) {
            this.renderer.removeChild(document.head, link);
        }
    }
}