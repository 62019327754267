<nav class="navbar navbar-expand-lg bg-light fixed-top">
  <div class="container-fluid">
    <a href="#" class="navbar-brand">
      <img class="img-fluid" src="./assets/img/vh%20bg-2.png">
    </a>
    <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
      <span class="navbar-toggler-icon"><i class="fa fa-bars" aria-hidden="true"></i></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navbarCollapse">
      <div class="navbar-nav text-bold">
        <a href="" class="nav-item nav-link active-nav">Home</a>
        <a type="button" class="nav-item nav-link">About</a>
        <a type="button" class="nav-item nav-link">Appointment</a>
        <li class="nav-item dropdown">
          <a class="nav-link nav-item dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
             aria-expanded="false">
            Departments
          </a>
          <ul class="dropdown-menu">
            <li><a [routerLink]="['/best-diabetic-hospital-trichy']"
                   class="dropdown-item text-bold text-muted" href="#">Diabetics</a></li>
            <li><a [routerLink]="['/best-gynecologist-hospital-trichy']"
                   class="dropdown-item text-bold text-muted" href="#">Gynecologist</a></li>
            <li><a [routerLink]="['/best-psychiatrist-hospital-trichy']"
                   class="dropdown-item text-bold text-muted"
                   href="#">Psychiatrist</a></li>
            <li><a [routerLink]="['/best-ortho-hospital-trichy']"
                   class="dropdown-item text-bold text-muted" href="#">Orthopedic</a></li>
            <li><a [routerLink]="['/best-dermatologist-doctor-trichy']"
                   class="dropdown-item text-bold text-muted" href="#">Dermatologist</a></li>
            <li><a [routerLink]="['/best-physiotherapy-doctor-trichy']"
                   class="dropdown-item text-bold text-muted"
                   href="#">Physiotherapy</a></li>
          </ul>
        </li>
        <a type="button" class="nav-item nav-link" [routerLink]="['/']">Doctors</a>
        <a type="button" class="nav-item nav-link" [routerLink]="['/']">Services</a>
        <a type="button" class="nav-item nav-link" [routerLink]="['/']">Contacts</a>
      </div>
    </div>
  </div>
</nav>
<div class="container ml-2 mt-5">

  <div class="row mt-5">
    <div class="col-lg-12 col-12 mt-5">
      <h1>Who we are</h1>
    </div>
    <div class="col-lg-12 col-12">
      <h5>Our website address is: <a href="https://varshinihospital.com" class="text-black"
                                                    style="margin: 0"
                                                    target="_blank"
      ><span class="text-black">https://varshinihospital.com</span></a></h5>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-lg-12 col-12">
      <h2>Comments</h2>
    </div>
    <div class="col-lg-12 col-12">
      <p>When visitors leave comments on the site we collect the data shown in the
        comments
        form, and also the visitor’s IP address and browser user agent string to help spam detection.</p>

      <p>An anonymized string created from your email address (also called a hash) may be provided to the Gravatar
        service
        to see if you are using it. The Gravatar service privacy policy is available here:
        <a href="https://varshinihospital.com/privacy" class="text-black"
           style="margin: 0"
           target="_blank"
        ><span class="text-black">https://varshinihospital.com/privacy</span></a>
        After approval of your comment, your profile picture is visible to the public in the context of your comment.
      </p>
      <h2>Media</h2>
      <p>If you upload images to the website, you should avoid uploading images with
        embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data
        from
        images on the website.</p>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-lg-12 col-12">
      <h2>Cookies</h2>
    </div>
    <div class="col-lg-12 col-12">
      <p>If you leave a comment on our site you may opt-in to saving your name, email
        address and website in cookies. These are for your convenience so that you do not have to fill in your details
        again
        when you leave another comment. These cookies will last for one year.</p>
      <p>If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This
        cookie contains no personal data and is discarded when you close your browser.</p>
      <p>When you log in, we will also set up several cookies to save your login information and your screen display
        choices. Login cookies last for two days, and screen options cookies last for a year. If you select “Remember
        Me”,
        your login will persist for two weeks. If you log out of your account, the login cookies will be removed.</p>
      <p>If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no
        personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.
      </p>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-lg-12 col-12">
      <h2>Embedded content from other websites</h2>
    </div>
    <div class="col-lg-12 col-12">
      <p>Articles on this site may include embedded content (e.g. videos, images,
        articles,
        etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the
        other
        website.</p>
      <p>These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your
        interaction with that embedded content, including tracking your interaction with the embedded content if you
        have an
        account and are logged in to that website.</p>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-lg-12 col-12">
      <h2>Who we share your data with</h2>
    </div>
    <div class="col-lg-12 col-12">
      <p>If you request a password reset, your IP address will be included in the reset
        email.
      </p>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-lg-12 col-12">
      <h2>How long we retain your data</h2>
    </div>
    <div class="col-lg-12 col-12">
      <p>If you leave a comment, the comment and its metadata are retained
        indefinitely.
        This is so we can recognize and approve any follow-up comments automatically instead of holding them in a
        moderation
        queue.

        For users that register on our website (if any), we also store the personal information they provide in their
        user
        profile. All users can see, edit, or delete their personal information at any time (except they cannot change
        their
        username). Website administrators can also see and edit that information.
      </p>
    </div>
  </div>


  <div class="row mt-2">
    <div class="col-lg-12 col-12">
      <h2>What rights you have over your data</h2>
    </div>
    <div class="col-lg-12 col-12">
      <p>If you have an account on this site, or have left comments, you can request to receive an
        exported
        file of the personal data we hold about you, including any data you have provided to us. You can also request
        that
        we erase any personal data we hold about you. This does not include any data we are obliged to keep for
        administrative, legal, or security purposes.</p>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-lg-12 col-12">
      <h2>Termination</h2>
    </div>
    <div class="col-lg-12 col-12">
      <p>The Company reserves the right to terminate a user’s access to the website at its discretion.</p>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-lg-12 col-12">
      <h2>Governing Law</h2>
    </div>
    <div class="col-lg-12 col-12">
      <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of Indian
        Jurisdiction.</p>
    </div>
  </div>


  <div class="row mt-2">
    <div class="col-lg-12 col-12">
      <h2>Where we send your data</h2>
    </div>
    <div class="col-lg-12 col-12">
      <p>Suggested text: Visitor comments may be checked through an automated spam detection service.</p>
    </div>
  </div>
</div>


<div class="container-fluid p-0 mt-0">

  <footer class="text-center" style="background-color: #b3c9ff">
    <div class="text-center text-bold text-dark p-3" style="background-color: rgba(181,215,234,0.2);">
      <span>Copyright &copy; {{ currentYear }}  </span>
      <a href="https://varnikcloud.com/"
         style="margin: 0; text-decoration: none"
         target="_blank"
      ><span class="text-dark">  VarNik Systems Services Pvt. Ltd</span></a>
      <span>. </span>
      <span>  All rights reserved.</span>
    </div>
    <div class="text-center text-bold" style="background-color: rgba(181,215,234,0.2);">
      <span class="text-black mr-2 text-end" type="button" [routerLink]="['/terms']">Terms & Conditions <span>|</span>
        <span class="text-black ml-1 text-end" type="button" [routerLink]="['/privacy']">Privacy Policy</span></span>
    </div>
  </footer>

</div>
