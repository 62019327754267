import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import {WebsiteComponent} from "@modules/website/website.component";
import {TermsComponent} from "@modules/terms-and-conditions/terms.component";
import {PrivacyPolicyComponent} from "@modules/privacy-policy/privacy-policy.component";
import {GynecologistComponent} from "@modules/departments/gynecologist/gynecologist.component";
import {DiabeticsComponent} from "@modules/departments/diabetics/diabetics.component";
import {PsychiatristComponent} from "@modules/departments/psychiatrist/psychiatrist.component";
import {OrthoComponent} from "@modules/departments/orthopedic/ortho.component";
import {DermatologistComponent} from "@modules/departments/dermatologist/dermatologist.component";
import {PhysiotherapyComponent} from "@modules/departments/physiotherapy/physiotherapy.component";

const routes: Routes = [
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'blank',
        component: BlankComponent
      },
      {
        path: 'sub-menu-1',
        component: SubMenuComponent
      },
      {
        path: 'sub-menu-2',
        component: BlankComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      }
    ]
  },
  {
    path: '',
    component: WebsiteComponent,
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  {
    path: 'privacy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'best-gynecologist-hospital-trichy',
    component: GynecologistComponent
  },
  {
    path: 'best-diabetic-hospital-trichy',
    component: DiabeticsComponent
  },
  {
    path: 'best-psychiatrist-hospital-trichy',
    component: PsychiatristComponent
  },
  {
    path: 'best-ortho-hospital-trichy',
    component: OrthoComponent
  },
  {
    path: 'best-dermatologist-doctor-trichy',
    component: DermatologistComponent
  },
  {
    path: 'best-physiotherapy-doctor-trichy',
    component: PhysiotherapyComponent
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'recover-password',
    component: RecoverPasswordComponent,
    canActivate: [NonAuthGuard]
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
