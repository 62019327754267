<nav class="navbar navbar-expand-lg bg-light fixed-top">
    <div class="container-fluid">
        <a href="#" class="navbar-brand">
            <img class="img-fluid" src="./assets/img/vh%20bg-2.png">
        </a>
        <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span class="navbar-toggler-icon"><i class="fa fa-bars" aria-hidden="true"></i></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarCollapse">
            <div class="navbar-nav text-bold">
                <a href="" class="nav-item nav-link active-nav" [routerLink]="['/']">Home</a>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">About</a>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">Services</a>
                <li class="nav-item dropdown">
                    <a class="nav-link nav-item dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                       aria-expanded="false" [routerLink]="['/']">
                        Departments
                    </a>
                    <ul class="dropdown-menu">
                        <li><a [routerLink]="['/best-diabetic-hospital-trichy']"
                               class="dropdown-item text-bold text-muted" href="#">Diabetics</a></li>
                        <li><a [routerLink]="['/best-gynecologist-hospital-trichy']"
                               class="dropdown-item text-bold text-muted" href="#">Gynecologist</a></li>
                        <li><a [routerLink]="['/best-psychiatrist-hospital-trichy']"
                               class="dropdown-item text-bold text-muted"
                               href="#">Psychiatrist</a></li>
                        <li><a [routerLink]="['/best-ortho-hospital-trichy']"
                               class="dropdown-item text-bold text-muted" href="#">Orthopedic</a></li>
                        <li><a [routerLink]="['/best-dermatologist-doctor-trichy']"
                               class="dropdown-item text-bold text-muted" href="#">Dermatologist</a></li>
                        <li><a [routerLink]="['/best-physiotherapy-doctor-trichy']"
                               class="dropdown-item text-bold text-muted"
                               href="#">Physiotherapy</a></li>
                    </ul>
                </li>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">Doctors</a>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">Appointment</a>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">Contacts</a>
            </div>
        </div>
    </div>
</nav>

<div class="container-fluid">
    <div class="row mt-sm-4"></div>
    <div class="row">
        <img src="./assets/img/slider%20image/physio.jpg" alt="dermatologist" class="img-fluid">
    </div>

    <div class="container mt-5">
        <div class="row mt-5 mb-3">
            <h3 class="text-primary text-center text-bold"> Why Choose Varshini Hospital  for Physiotherapy Care in
                Trichy?</h3>
        </div>
        <div class="row mt-5">
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 17rem">
                    <div class="card-body">
                        <p>Varshini Hospital in Trichy stands as a beacon of excellence in rehabilitative care, offering
                            unparalleled physiotherapy services to individuals seeking optimal recovery and improved
                            physical
                            well-being. Led by a team of highly skilled physiotherapists, the clinic is dedicated to
                            providing
                            personalized and effective solutions for a wide range of musculoskeletal issues.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 17rem">
                    <div class="card-body">
                        <p>The clinic
                            is equipped with state-of-the-art facilities and cutting-edge technology, ensuring that
                            patients receive the latest and most effective physiotherapeutic interventions. Whether
                            recovering from
                            injuries, managing chronic conditions, or enhancing athletic performance, the clinic covers
                            a
                            comprehensive spectrum of physiotherapy needs.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 17rem">
                    <div class="card-body">
                        <p>The clinic's commitment to individualized care is a cornerstone of its
                            success. Each patient undergoes a thorough assessment, and a customized treatment plan is
                            crafted to
                            address their specific concerns. The goal is not just to alleviate symptoms but to empower
                            individuals with the tools for long-term physical health.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 17rem">
                    <div class="card-body">
                        <p>Positive testimonials from satisfied patients highlight the clinic's
                            reputation for delivering exceptional results. Patients appreciate the compassionate and
                            professional approach of the physiotherapists, the welcoming atmosphere, and the positive
                            impact of
                            the clinic's interventions on their overall quality of life.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 17rem">
                    <div class="card-body">
                        <p>The clinic's welcoming atmosphere and professional staff contribute to
                            an environment where patients feel cared for and supported.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 17rem">
                    <div class="card-body">
                        <p>In addition to its clinical services, Varshini Hospital actively
                            engages in community outreach, organizing workshops, and promoting awareness about the
                            importance of
                            physiotherapy in maintaining and restoring physical function. This commitment reflects the
                            clinic's
                            dedication to enhancing the overall physical well-being of the Trichy community.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 17rem">
                    <div class="card-body">
                        <p>Conveniently located, Varshini Hospital ensures accessibility for
                            residents across Trichy. The clinic's combination of expertise, advanced technology, and a
                            patient-focused approach positions it as the best physiotherapy clinic in the region,
                            dedicated to
                            providing top-tier rehabilitative care for individuals of all ages and backgrounds.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 17rem">
                    <div class="card-body">
                        <p>Positive feedback from satisfied patients underscores the clinic's
                            reputation for delivering exceptional results. Patients appreciate the caring and
                            professional
                            demeanor of the physiotherapists, creating a supportive and encouraging environment.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 17rem">
                    <div class="card-body">
                        <p>In addition to clinical excellence, Varshini Hospital actively
                            contributes to community well-being through educational initiatives and awareness campaigns.
                            This
                            commitment reflects the clinic's dedication to promoting the importance of physiotherapy in
                            maintaining and restoring physical function.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid p-0 mt-0">

    <footer class="text-center" style="background-color: #b3c9ff">
        <div class="text-center text-bold text-dark p-3" style="background-color: rgba(181,215,234,0.2);">
            <span>Copyright &copy; {{ currentYear }}  </span>
            <a href="https://varnikcloud.com/"
               style="margin: 0; text-decoration: none"
               target="_blank"
            ><span class="text-dark">  VarNik Systems Services Pvt. Ltd</span></a>
            <span>. </span>
            <span>  All rights reserved.</span>
        </div>
        <div class="text-center text-bold" style="background-color: rgba(181,215,234,0.2);">
      <span class="text-black mr-2 text-end" type="button" [routerLink]="['/terms']">Terms & Conditions <span>|</span>
        <span class="text-black ml-1 text-end" type="button" [routerLink]="['/privacy']">Privacy Policy</span></span>
        </div>
    </footer>

</div>