import {Component, Renderer2} from "@angular/core";
import {DateTime} from 'luxon';
import {Meta, Title} from "@angular/platform-browser";
@Component({
    selector: 'app-gynecologist',
    templateUrl: './gynecologist.component.html',
    styleUrls: ['./gynecologist.component.scss'],
})

export class GynecologistComponent {
    public currentYear: string = DateTime.now().toFormat('y');

    constructor(
        private meta: Meta, private titleService: Title, private renderer: Renderer2
    ) {

        this.titleService.setTitle('Best Gynecologist Hospital in Trichy | Gynecologist in KK Nagar');
        this.meta.addTag({
            name: 'description',
            content: 'Trusted Gynecologist Hospital in Trichy. Exceptional care, expert specialists, and compassionate support at Varshini Hospital Your well-being is our priority.'
        });
        this.meta.addTag({
            name: 'keywords',
            content: 'gynecologist in trichy, best gynaecologist in trichy, best gynecologist hospital in trichy, gynecologist in trichy kk nagar, trichy best gynecologist, best multi speciality hospital in trichy, trichy multi speciality hospitals, multi speciality hospitals in trichy, multi speciality hospital in trichy'
        });
    }

    ngOnInit() {
        this.addCanonicalLink();
    }

    ngOnDestroy() {
        this.removeCanonicalLink();
    }

    private addCanonicalLink() {
        const link = this.renderer.createElement('link');
        this.renderer.setAttribute(link, 'rel', 'canonical');
        this.renderer.setAttribute(link, 'href', 'https://varshinihospital.com/best-gynecologist-hospital-trichy');
        this.renderer.appendChild(document.head, link);
    }

    private removeCanonicalLink() {
        const link = document.head.querySelector('link[rel="canonical"]');
        if (link) {
            this.renderer.removeChild(document.head, link);
        }
    }
}