import {Component, Renderer2, ViewEncapsulation} from "@angular/core";
import {DateTime} from 'luxon';
import {Meta, Title} from "@angular/platform-browser";
@Component({
    selector: 'app-gynecologist',
    templateUrl: './diabetics.component.html',
    styleUrls: ['./diabetics.component.scss'],
})

export class DiabeticsComponent {
    public currentYear: string = DateTime.now().toFormat('y');

    constructor(
        private meta: Meta, private titleService: Title, private renderer: Renderer2
    ) {

        this.titleService.setTitle('Best Diabetic Hospital in Trichy | Trichy Diabetes Center');
        this.meta.addTag({
            name: 'description',
            content: 'Leading Diabetic Hospital in Trichy—expert care, personalized treatment, advanced technology. Trust Varshini Hospital  for superior diabetes management.'
        });
        this.meta.addTag({
            name: 'keywords',
            content: 'best diabetic hospital in trichy, trichy diabetes center, trichy diabetes hospital, diabetic hospital in trichy,best multi speciality hospital in trichy, trichy multi speciality hospitals, multi speciality hospitals in trichy, multi speciality hospital in trichy'
        });
    }

    ngOnInit() {
        this.addCanonicalLink();
    }

    ngOnDestroy() {
        this.removeCanonicalLink();
    }

    private addCanonicalLink() {
        const link = this.renderer.createElement('link');
        this.renderer.setAttribute(link, 'rel', 'canonical');
        this.renderer.setAttribute(link, 'href', 'https://varshinihospital.com/best-diabetic-hospital-trichy');
        this.renderer.appendChild(document.head, link);
    }

    private removeCanonicalLink() {
        const link = document.head.querySelector('link[rel="canonical"]');
        if (link) {
            this.renderer.removeChild(document.head, link);
        }
    }
}