import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Gatekeeper} from 'gatekeeper-client-sdk';
import {FormGroup} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {PatientDetails} from "@/modal/patientDetails";

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;


    constructor(private router: Router, private toastr: ToastrService, private http: HttpClient) {
    }

    endPoint = "https://tomcat3.varnik.cloud:8443/amdds-ws";

    async loginByAuth({email, password}) {
        try {
            const token = await Gatekeeper.loginByAuth(email, password);
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
            this.toastr.success('Login success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByAuth({email, password}) {
        try {
            const token = await Gatekeeper.registerByAuth(email, password);
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
            this.toastr.success('Register success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async loginByGoogle() {
        try {
            const token = await Gatekeeper.loginByGoogle();
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
            this.toastr.success('Login success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByGoogle() {
        try {
            const token = await Gatekeeper.registerByGoogle();
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
            this.toastr.success('Register success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async loginByFacebook() {
        try {
            const token = await Gatekeeper.loginByFacebook();
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
            this.toastr.success('Login success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByFacebook() {
        try {
            const token = await Gatekeeper.registerByFacebook();
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
            this.toastr.success('Register success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async getProfile() {
        try {
            this.user = await Gatekeeper.getProfile();
        } catch (error) {
            this.logout();
            throw error;
        }
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('gatekeeper_token');
        this.user = null;
        this.router.navigate(['/login']);
    }


    /*//Appointment patient Details
    savePatientDetails(audienceForm: FormGroup) {
        return this.http.post<PatientDetails>(
            this.endPoint + "/Amdds/api/patientAppointment/save", audienceForm
        )
    }*/

    sendContactDetails(contactDetails: any) {
        const formData = new FormData();
        formData.append('firstName', contactDetails.firstName);
        formData.append('lastName', contactDetails.lastName);
        formData.append('emailId', contactDetails.emailId);
        formData.append('phoneNo', contactDetails.phoneNo);
        formData.append('patientName',contactDetails.patientName)
        formData.append('appointmentDate', contactDetails.appointmentDate)
        formData.append('subject',"")
        formData.append('localSubject', 'Appointment Message From ' + contactDetails.emailId)
        // formData.append('localEmailId', 'acc.varshinihospital@gmail.com' + contactDetails.emailId)

        return this.http.post<PatientDetails>(
            this.endPoint + "/api/amdds/contactUs", formData
        )
    }
}
