<nav class="navbar navbar-expand-lg bg-light fixed-top">
    <div class="container-fluid">
        <a href="#" class="navbar-brand">
            <img class="img-fluid" src="./assets/img/vh%20bg-2.png">
        </a>
        <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span class="navbar-toggler-icon"><i class="fa fa-bars" aria-hidden="true"></i></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarCollapse">
            <div class="navbar-nav text-bold">
                <a href="" class="nav-item nav-link active-nav" [routerLink]="['/']">Home</a>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">About</a>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">Services</a>
                <li class="nav-item dropdown">
                    <a class="nav-link nav-item dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                       aria-expanded="false" [routerLink]="['/']">
                        Departments
                    </a>
                    <ul class="dropdown-menu">
                        <li><a [routerLink]="['/best-diabetic-hospital-trichy']"
                               class="dropdown-item text-bold text-muted" href="#">Diabetics</a></li>
                        <li><a [routerLink]="['/best-gynecologist-hospital-trichy']"
                               class="dropdown-item text-bold text-muted" href="#">Gynecologist</a></li>
                        <li><a [routerLink]="['/best-psychiatrist-hospital-trichy']"
                               class="dropdown-item text-bold text-muted"
                               href="#">Psychiatrist</a></li>
                        <li><a [routerLink]="['/best-ortho-hospital-trichy']"
                               class="dropdown-item text-bold text-muted" href="#">Orthopedic</a></li>
                        <li><a [routerLink]="['/best-dermatologist-doctor-trichy']"
                               class="dropdown-item text-bold text-muted" href="#">Dermatologist</a></li>
                        <li><a [routerLink]="['/best-physiotherapy-doctor-trichy']"
                               class="dropdown-item text-bold text-muted"
                               href="#">Physiotherapy</a></li>
                    </ul>
                </li>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">Doctors</a>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">Appointment</a>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">Contacts</a>
            </div>
        </div>
    </div>
</nav>

<div class="container-fluid">
    <div class="row mt-sm-4"></div>
    <div class="row">
        <img src="./assets/img/slider%20image/pschiatry.jpg" alt="psychiatrist" class="img-fluid w-100">
    </div>

    <div class="container col-10 mt-5">
        <div class="col-lg-12 col-12">
            <h5 class="text-bold about-content">
                Welcome to Varshini Hospital Trichy, your premier destination for exceptional mental health care in
                Trichy. Recognized as the best psychiatrist hospital in the region, we are committed to providing
                comprehensive and compassionate psychiatric services to individuals seeking support for mental health
                and well-being.
            </h5>

            <div class="row mt-5">
                <h3 class="text-primary text-bold"> Why Choose Varshini Hospital for Psychiatric Care in
                    Trichy?</h3>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 17rem">
                    <div class="card-body">
                        <h3 style="text-align: center">Expert Psychiatrists and Mental Health Professionals</h3>
                        <p>Varshini Hospital is home to a team of highly qualified and experienced psychiatrists,
                            psychologists, and mental health specialists dedicated to providing personalized and
                            effective
                            care.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 17rem">
                    <div class="card-body">
                        <h3 style="text-align: center">Comprehensive Psychiatric Services</h3>
                        <p>From diagnostic assessments to evidence-based treatments, our hospital
                            offers a broad spectrum of psychiatric services, including counseling, psychotherapy,
                            medication
                            management, and more.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 17rem">
                    <div class="card-body">
                        <h3 style="text-align: center">State-of-the-Art Facilities</h3>
                        <p>Equipped with modern facilities and advanced technologies, Varshini
                            Hospital ensures a comfortable and supportive environment for individuals seeking
                            psychiatric
                            care.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 17rem">
                    <div class="card-body">
                        <h3 style="text-align: center">Patient-Centric Care</h3>
                        <p>Your mental health journey is our priority. Varshini Hospital is
                            committed to providing patient-centric care, fostering open communication, and creating a
                            supportive
                            atmosphere for those seeking assistance.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 17rem">
                    <div class="card-body">
                        <h3 style="text-align: center">Accreditations and Recognitions</h3>
                        <p>Trust in the quality of our mental health services. Varshini Hospital
                            Trichy holds accreditations and recognitions, signifying our commitment to maintaining the
                            highest
                            standards in psychiatric care.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 17rem">
                    <div class="card-body">
                        <h3 style="text-align: center">Patient-Centric Care</h3>
                        <p>Your comfort and confidence in our care are paramount,
                            Varshini Hospital is committed to providing a patient-centric environment,fostering open
                            communication and ensuring that you feel supported throughout your healthcare journey.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4"></div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 17rem">
                    <div class="card-body">
                        <h3 style="text-align: center">Contact Varshini Hospital Today</h3>
                        <p>Ready to prioritize your mental health? Contact Varshini Hospital at to
                            schedule a consultation. Let us be your trusted partner on the journey to mental
                            wellness.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid p-0 mt-0">

    <footer class="text-center" style="background-color: #b3c9ff">
        <div class="text-center text-bold text-dark p-3" style="background-color: rgba(181,215,234,0.2);">
            <span>Copyright &copy; {{ currentYear }}  </span>
            <a href="https://varnikcloud.com/"
               style="margin: 0; text-decoration: none"
               target="_blank"
            ><span class="text-dark">  VarNik Systems Services Pvt. Ltd</span></a>
            <span>. </span>
            <span>  All rights reserved.</span>
        </div>
        <div class="text-center text-bold" style="background-color: rgba(181,215,234,0.2);">
      <span class="text-black mr-2 text-end" type="button" [routerLink]="['/terms']">Terms & Conditions <span>|</span>
        <span class="text-black ml-1 text-end" type="button" [routerLink]="['/privacy']">Privacy Policy</span></span>
        </div>
    </footer>
</div>
