<nav class="navbar navbar-expand-lg bg-light fixed-top">
    <div class="container-fluid">
        <a href="#" class="navbar-brand">
            <img class="img-fluid" src="./assets/img/vh%20bg-2.png">
        </a>
        <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span class="navbar-toggler-icon"><i class="fa fa-bars" aria-hidden="true"></i></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarCollapse">
            <div class="navbar-nav text-bold">
                <a href="" class="nav-item nav-link active-nav" [routerLink]="['/']">Home</a>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">About</a>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">Services</a>
                <li class="nav-item dropdown">
                    <a class="nav-link nav-item dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                       aria-expanded="false" [routerLink]="['/']">
                        Departments
                    </a>
                    <ul class="dropdown-menu">
                        <li><a [routerLink]="['/best-diabetic-hospital-trichy']"
                               class="dropdown-item text-bold text-muted" href="#">Diabetics</a></li>
                        <li><a [routerLink]="['/best-gynecologist-hospital-trichy']"
                               class="dropdown-item text-bold text-muted" href="#">Gynecologist</a></li>
                        <li><a [routerLink]="['/best-psychiatrist-hospital-trichy']"
                               class="dropdown-item text-bold text-muted"
                               href="#">Psychiatrist</a></li>
                        <li><a [routerLink]="['/best-ortho-hospital-trichy']"
                               class="dropdown-item text-bold text-muted" href="#">Orthopedic</a></li>
                        <li><a [routerLink]="['/best-dermatologist-doctor-trichy']"
                               class="dropdown-item text-bold text-muted" href="#">Dermatologist</a></li>
                        <li><a [routerLink]="['/best-physiotherapy-doctor-trichy']"
                               class="dropdown-item text-bold text-muted"
                               href="#">Physiotherapy</a></li>
                    </ul>
                </li>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">Doctors</a>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">Appointment</a>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">Contacts</a>
            </div>
        </div>
    </div>
</nav>

<div class="container-fluid">
    <div class="row mt-sm-4"></div>
    <div class="row">
        <img src="./assets/img/slider%20image/gyneacology.jpg" alt="gynecology" class="img-fluid w-100 h-auto">
    </div>
    <div class="row mt-5">
        <div class="col-lg-12 text-center col-12">
            <h4 class="text-bold about-content">
                Why Choose Varshini Hospital for Gynecological Care in Trichy?
            </h4>
        </div>
    </div>

    <div class="container">
        <div class="row mt-5">
            <div class="col-lg-3 col-12 col-md-6">
                <div class="card" style="height: 19rem">
                    <div class="card-body">
                        <h3 style="text-align: center">Expert Gynecologists and Obstetricians</h3>
                        <p>Varshini Hospital boasts a team of highly skilled and experienced gynecologists and
                            obstetricians
                            dedicated to providing compassionate and personalized care for women of all ages.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-12 col-md-6">
                <div class="card" style="height: 19rem">
                    <div class="card-body">
                        <h3 style="text-align: center">Comprehensive Gynecological Services</h3>
                        <p>From routine gynecological check-ups to advanced treatments, our
                            hospital offers a wide array of services, including prenatal care, family planning,
                            fertility
                            services, and menopause management.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-12 col-md-6">
                <div class="card" style="height: 19rem">
                    <div class="card-body">
                        <h3 style="text-align: center">State-of-the-Art Diagnostic Facilities</h3>
                        <p>Equipped with the latest diagnostic technologies, Varshini Hospital
                            ensures accurate and timely assessments, enabling our specialists to formulate precise
                            treatment
                            plans.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-12 col-md-6">
                <div class="card" style="height: 19rem">
                    <div class="card-body">
                        <h3 style="text-align: center;">Maternity Care Excellence</h3>
                        <p>Experience the joy of motherhood Varshini Hospital in Trichy
                            exceptional maternity care services. Our commitment to your well-being extends from prenatal
                            care to
                            delivery, providing support at every stage.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-12 col-md-6">
                <div class="card" style="height: 19rem">
                    <div class="card-body">
                        <h3 style="text-align: center">Gynecological Surgery Expertise</h3>
                        <p>For cases requiring surgical intervention, our hospital excels in
                            gynecological surgeries, employing advanced techniques to ensure optimal outcomes and faster
                            recovery.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-12 col-md-6">
                <div class="card" style="height: 19rem">
                    <div class="card-body">
                        <h3 style="text-align: center">Patient-Centric Care</h3>
                        <p>Your comfort and confidence in our care are paramount. KK Nagar
                            Varshini Hospital is committed to providing a patient-centric environment, fostering open
                            communication and ensuring that you feel supported throughout your healthcare journey.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-12 col-md-6">
                <div class="card" style="height: 19rem">
                    <div class="card-body">
                        <h3 style="text-align: center">Accreditations and Recognitions</h3>
                        <p>Trust in the quality of our services. Varshini Hospital holds
                            accreditations and recognitions, underscoring our dedication to maintaining the highest
                            standards in
                            gynecological care.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-12 col-md-6">
                <div class="card" style="height: 19rem">
                    <div class="card-body">
                        <h3 style="text-align: center;">Contact Varshini Hospital Today</h3>
                        <p>Ready to experience the best in gynecological care in Trichy? Contact
                            Varshini Hospital .Let us be your trusted partner in safeguarding and enhancing your women's
                            health
                            journey.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--    <div class="col-lg-6  col-12 col-md-12">-->
    <!--        <img src="./assets/img/Hospital%20family%20visit-amico.png" class="img-fluid animated">-->
    <!--    </div>-->

</div>

<div class="container-fluid p-0 mt-0">

    <footer class="text-center" style="background-color: #b3c9ff">
        <div class="text-center text-bold text-dark p-3" style="background-color: rgba(181,215,234,0.2);">
            <span>Copyright &copy; {{ currentYear }}  </span>
            <a href="https://varnikcloud.com/"
               style="margin: 0; text-decoration: none"
               target="_blank"
            ><span class="text-dark">  VarNik Systems Services Pvt. Ltd</span></a>
            <span>. </span>
            <span>  All rights reserved.</span>
        </div>
        <div class="text-center text-bold" style="background-color: rgba(181,215,234,0.2);">
      <span class="text-black mr-2 text-end" type="button" [routerLink]="['/terms']">Terms & Conditions <span>|</span>
        <span class="text-black ml-1 text-end" type="button" [routerLink]="['/privacy']">Privacy Policy</span></span>
        </div>
    </footer>

</div>