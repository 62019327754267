import {Component, Renderer2} from "@angular/core";
import {DateTime} from 'luxon';
import {Meta, Title} from "@angular/platform-browser";
@Component({
    selector: 'app-orthopedic',
    templateUrl: './ortho.component.html',
    styleUrls: ['./ortho.component.scss'],
})

export class OrthoComponent {
    public currentYear: string = DateTime.now().toFormat('y');

    constructor(
        private meta: Meta, private titleService: Title, private renderer: Renderer2
    ) {

        this.titleService.setTitle('Best Ortho Hospital in Trichy | Ortho Clinic in KK Nagar Trichy ');
        this.meta.addTag({
            name: 'description',
            content: ' Trichy Top Ortho Hospital—Exceptional care, skilled orthopedic specialists, and cutting-edge treatments await you at Varshini Hospital KK  Nagar Trichy'
        });
        this.meta.addTag({
            name: 'keywords',
            content: 'ortho clinic in trichy, best ortho hospital in trichy, best ortho in trichy,best multi speciality hospital in trichy, trichy multi speciality hospitals, multi speciality hospitals in trichy, multi speciality hospital in trichy'
        });
    }

    ngOnInit() {
        this.addCanonicalLink();
    }

    ngOnDestroy() {
        this.removeCanonicalLink();
    }

    private addCanonicalLink() {
        const link = this.renderer.createElement('link');
        this.renderer.setAttribute(link, 'rel', 'canonical');
        this.renderer.setAttribute(link, 'href', 'https://varshinihospital.com/best-ortho-hospital-trichy');
        this.renderer.appendChild(document.head, link);
    }

    private removeCanonicalLink() {
        const link = document.head.querySelector('link[rel="canonical"]');
        if (link) {
            this.renderer.removeChild(document.head, link);
        }
    }
}