<nav class="navbar navbar-expand-lg bg-light fixed-top">
    <div class="container-fluid">
        <a href="#" class="navbar-brand">
            <img class="img-fluid" src="./assets/img/vh%20bg-2.png">
        </a>
        <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span class="navbar-toggler-icon"><i class="fa fa-bars" aria-hidden="true"></i></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarCollapse">
            <div class="navbar-nav text-bold">
                <a href="" class="nav-item nav-link active-nav" [routerLink]="['/']">Home</a>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">About</a>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">Services</a>
                <li class="nav-item dropdown">
                    <a class="nav-link nav-item dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                       aria-expanded="false" [routerLink]="['/']">
                        Departments
                    </a>
                    <ul class="dropdown-menu">
                        <li><a [routerLink]="['/best-diabetic-hospital-trichy']"
                               class="dropdown-item text-bold text-muted" href="#">Diabetics</a></li>
                        <li><a [routerLink]="['/best-gynecologist-hospital-trichy']"
                               class="dropdown-item text-bold text-muted" href="#">Gynecologist</a></li>
                        <li><a [routerLink]="['/best-psychiatrist-hospital-trichy']"
                               class="dropdown-item text-bold text-muted"
                               href="#">Psychiatrist</a></li>
                        <li><a [routerLink]="['/best-ortho-hospital-trichy']"
                               class="dropdown-item text-bold text-muted" href="#">Orthopedic</a></li>
                        <li><a [routerLink]="['/best-dermatologist-doctor-trichy']"
                               class="dropdown-item text-bold text-muted" href="#">Dermatologist</a></li>
                        <li><a [routerLink]="['/best-physiotherapy-doctor-trichy']"
                               class="dropdown-item text-bold text-muted"
                               href="#">Physiotherapy</a></li>
                    </ul>
                </li>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">Doctors</a>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">Appointment</a>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">Contacts</a>
            </div>
        </div>
    </div>
</nav>

<div class="container-fluid">
    <div class="row mt-sm-4"></div>
    <div class="row">
        <img src="./assets/img/slider%20image/dermatology.jpg" alt="dermatologist" class="img-fluid w-100">
    </div>

    <div class="container mt-5">
        <div class="row mt-5 mb-3">
            <h3 class="text-primary text-center text-bold"> Why Choose Varshini Hospital  for Dermatologist  Care in
                Trichy?</h3>
        </div>
        <div class="row">
            <div class="col-lg-3 col-12 col-md-6">
                <div class="card" style="height: 20rem">
                    <div class="card-body">
                        <p>Dr. R.Seetha Lakshmi stands out as the preeminent dermatologist in Trichy, renowned for
                            delivering
                            exceptional skincare solutions. With a wealth of experience and a commitment to excellence,
                            Dr.
                            R.Seetha Lakshmi has become the trusted name in dermatological care in the region.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-12 col-md-6">
                <div class="card" style="height: 20rem">
                    <div class="card-body">
                        <p>The clinic, under their guidance, offers a spectrum of dermatological
                            treatments, addressing issues ranging from common skin conditions to cosmetic
                            enhancements.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-12 col-md-6">
                <div class="card" style="height: 20rem">
                    <div class="card-body">
                        <p>Patients appreciate the doctor's ability to listen, understand, and
                            provide tailored solutions, fostering a sense of trust and comfort.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-12 col-md-6">
                <div class="card" style="height: 20rem">
                    <div class="card-body">
                        <p>The clinic is equipped with cutting-edge technology, ensuring that
                            patients receive the latest advancements in dermatological care. From acne treatments to
                            advanced
                            cosmetic procedures, the clinic covers a comprehensive range of services.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-12 col-md-6">
                <div class="card" style="height: 20rem">
                    <div class="card-body">
                        <p>The clinic's welcoming atmosphere and professional staff contribute to
                            an environment where patients feel cared for and supported.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-12 col-md-6">
                <div class="card" style="height: 20rem">
                    <div class="card-body">
                        <p>Dr. R.Seetha Lakshmi goes beyond individual care, actively engaging in
                            community outreach to promote skin health awareness. This dedication to education aligns
                            with the
                            clinic's mission of not only treating existing skin concerns but also preventing future
                            issues.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-12 col-md-6">
                <div class="card" style="height: 20rem">
                    <div class="card-body">
                        <p>Conveniently located, the clinic ensures accessibility for the diverse
                            population of Trichy. Dr. R.Seetha Lakshmi and their team remain steadfast in their
                            commitment to
                            providing the best dermatological care, combining expertise, empathy, and a patient-centered
                            approach to enhance the skin health of their community.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-12 col-md-6">
                <div class="card" style="height: 20rem">
                    <div class="card-body">
                        <p>Varshini Hospital in Trichy stands out as the epitome of excellence in
                            skincare, offering a comprehensive range of services for individuals seeking optimal skin
                            health.
                            Led by a team of experienced dermatologists and skincare professionals, the clinic is
                            dedicated to
                            providing personalized and effective solutions for various skin concerns.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-12 col-md-6">
                <div class="card" style="height: 20rem">
                    <div class="card-body">
                        <p>At Varshini Hospital, cutting-edge technology meets a patient-centric
                            approach. The clinic is well-equipped with state-of-the-art facilities, ensuring that
                            patients
                            receive the latest advancements in dermatological care. From routine skin consultations to
                            specialized treatments, the clinic covers a diverse spectrum of skincare needs.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-12 col-md-6">
                <div class="card" style="height: 20rem">
                    <div class="card-body">
                        <p>The clinic's commitment to individualized care is evident in the
                            personalized treatment plans crafted for each patient. Whether addressing acne, dermatitis,
                            or
                            cosmetic enhancements, the clinic tailors its services to meet the unique requirements of
                            every
                            individual.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-12 col-md-6">
                <div class="card" style="height: 20rem">
                    <div class="card-body">
                        <p>Positive feedback from satisfied clients underscores the clinic's
                            reputation for delivering exceptional results. Patients appreciate the welcoming atmosphere,
                            professional staff, and the transformative impact of the clinic's skincare interventions on
                            their
                            overall well-being.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-12 col-md-6">
                <div class="card" style="height: 20rem">
                    <div class="card-body">
                        <p>Conveniently located, Varshini Hospital ensures accessibility for
                            residents across Trichy. The clinic's combination of expertise, advanced technology, and a
                            patient-focused approach positions it as the best skin clinic in the region, dedicated to
                            providing
                            top-tier skincare solutions for its diverse clientele.</p>
                    </div>
                </div>
            </div>
        </div>


        <div class="row mt-2">
            <div class="col-lg-12">
                <h5 class="text-muted mt-2 ml-5">At Varshini Hospital, cutting-edge technology meets a patient-centric
                    approach. The clinic is well-equipped with state-of-the-art facilities, ensuring that patients
                    receive the latest advancements in dermatological care. From routine skin consultations to
                    specialized treatments, the clinic covers a diverse spectrum of skincare needs.</h5>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid p-0 mt-0">

    <footer class="text-center" style="background-color: #b3c9ff">
        <div class="text-center text-bold text-dark p-3" style="background-color: rgba(181,215,234,0.2);">
            <span>Copyright &copy; {{ currentYear }}  </span>
            <a href="https://varnikcloud.com/"
               style="margin: 0; text-decoration: none"
               target="_blank"
            ><span class="text-dark">  VarNik Systems Services Pvt. Ltd</span></a>
            <span>. </span>
            <span>  All rights reserved.</span>
        </div>
        <div class="text-center text-bold" style="background-color: rgba(181,215,234,0.2);">
      <span class="text-black mr-2 text-end" type="button" [routerLink]="['/terms']">Terms & Conditions <span>|</span>
        <span class="text-black ml-1 text-end" type="button" [routerLink]="['/privacy']">Privacy Policy</span></span>
        </div>
    </footer>

</div>