<nav class="navbar navbar-expand-lg bg-light fixed-top">
    <div class="container-fluid">
        <a href="#" class="navbar-brand">
            <img class="img-fluid" src="./assets/img/vh%20bg-2.png">
        </a>
        <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span class="navbar-toggler-icon"><i class="fa fa-bars" aria-hidden="true"></i></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarCollapse">
            <div class="navbar-nav text-bold">
                <a href="" class="nav-item nav-link active-nav" [routerLink]="['/']">Home</a>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">About</a>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">Services</a>
                <li class="nav-item dropdown">
                    <a class="nav-link nav-item dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                       aria-expanded="false" [routerLink]="['/']">
                        Departments
                    </a>
                    <ul class="dropdown-menu">
                        <li><a [routerLink]="['/best-diabetic-hospital-trichy']"
                               class="dropdown-item text-bold text-muted" href="#">Diabetics</a></li>
                        <li><a [routerLink]="['/best-gynecologist-hospital-trichy']"
                               class="dropdown-item text-bold text-muted" href="#">Gynecologist</a></li>
                        <li><a [routerLink]="['/best-psychiatrist-hospital-trichy']"
                               class="dropdown-item text-bold text-muted"
                               href="#">Psychiatrist</a></li>
                        <li><a [routerLink]="['/best-ortho-hospital-trichy']"
                               class="dropdown-item text-bold text-muted" href="#">Orthopedic</a></li>
                        <li><a [routerLink]="['/best-dermatologist-doctor-trichy']"
                               class="dropdown-item text-bold text-muted" href="#">Dermatologist</a></li>
                        <li><a [routerLink]="['/best-physiotherapy-doctor-trichy']"
                               class="dropdown-item text-bold text-muted"
                               href="#">Physiotherapy</a></li>
                    </ul>
                </li>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">Doctors</a>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">Appointment</a>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">Contacts</a>
            </div>
        </div>
    </div>
</nav>

<div class="container-fluid">
    <div class="row mt-sm-4"></div>
    <div class="row">
        <img src="./assets/img/slider%20image/ortho.jpg" alt="orthopedic" class="img-fluid mt-5 w-100 h-auto">
    </div>

    <div class="container mt-5">
        <div class="row mt-5">
            <h3 class="text-primary text-center text-bold"> Why Choose Varshini Hospital for Ortho Care in
                Trichy?</h3>
        </div>
        <div class="row mt-5">
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 18rem">
                    <div class="card-body">
                        <p>Trichy boasts several reputable orthopedic hospitals known for their exceptional healthcare
                            services
                            and cutting-edge treatments.Varshini Hospital,renowned for its commitment to orthopedic
                            excellence.With a team of highly skilled orthopedic specialists,Surgeons,
                            and dedicated healthcare professionals,the hospital ensures personalized care for
                            patients with musculoskeletal conditions.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 18rem">
                    <div class="card-body">
                        <p>At Varshini Hospital in Trichy, state-of-the-art facilities meet
                            compassionate patient care. The hospital is equipped with advanced diagnostic tools, modern
                            operating theaters, and rehabilitation services, offering a holistic approach to orthopedic
                            treatment. Their commitment to staying abreast of the latest medical advancements ensures
                            that
                            patients receive the most innovative and effective interventions available.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 18rem">
                    <div class="card-body">
                        <p>Patient satisfaction is a hallmark of Varshini Hospital, with numerous
                            positive testimonials highlighting the hospital's expertise, compassion, and successful
                            outcomes.
                            The hospital's focus on minimizing recovery time and optimizing patient comfort sets it
                            apart as a
                            leader in orthopedic care in Trichy.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 18rem">
                    <div class="card-body">
                        <p>Moreover Varshini Hospital in Trichy is dedicated to community health
                            education, organizing outreach programs, and promoting preventive orthopedic measures. This
                            commitment reflects the hospital's vision of not just treating ailments but actively
                            contributing to
                            the overall well-being of the community.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 18rem">
                    <div class="card-body">
                        <p>The clinic's success stories are numerous, with patients lauding the
                            personalized attention, timely interventions, and positive outcomes. Whether addressing
                            common
                            orthopedic concerns or complex musculoskeletal issues, Varshini Hospital remains a trusted
                            choice
                            for those seeking specialized orthopedic care in Trichy.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 18rem">
                    <div class="card-body">
                        <p>Your comfort and confidence in our care are paramount. KK Nagar
                            Varshini Hospital is committed to providing a patient-centric environment, fostering open
                            communication and ensuring that you feel supported throughout your healthcare journey.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4"></div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 18rem">
                    <div class="card-body">
                        <p>Accessibility is key at Varshini Hospital, and the clinic takes pride
                            in its convenient location, making it easily reachable for individuals across Trichy. With a
                            commitment to excellence, a focus on patient empowerment, and a team of dedicated orthopedic
                            experts, [Clinic Name] stands as a premier ortho clinic in Trichy, dedicated to enhancing
                            the
                            mobility and well-being of its community.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid p-0 mt-0">

    <footer class="text-center" style="background-color: #b3c9ff">
        <div class="text-center text-bold text-dark " style="background-color: rgba(181,215,234,0.2);">
            <span>Copyright &copy; {{ currentYear }}  </span>
            <a href="https://varnikcloud.com/"
               style="margin: 0; text-decoration: none"
               target="_blank"
            ><span class="text-dark">  VarNik Systems Services Pvt. Ltd</span></a>
            <span>. </span>
            <span>  All rights reserved.</span>
        </div>
        <div class="text-center text-bold" style="background-color: rgba(181,215,234,0.2);">
      <span class="text-black  text-end" type="button" [routerLink]="['/terms']">Terms & Conditions <span>|</span>
        <span class="text-black  text-end" type="button" [routerLink]="['/privacy']">Privacy Policy</span></span>
        </div>
    </footer>

</div>