
import {Component, OnDestroy, OnInit, Renderer2} from "@angular/core";
import {DateTime} from 'luxon';
import {Meta, Title} from "@angular/platform-browser";
@Component({
    selector: 'app-dermatologist',
    templateUrl: './dermatologist.component.html',
    styleUrls: ['./dermatologist.component.scss'],
})

export class DermatologistComponent implements OnInit, OnDestroy {
    public currentYear: string = DateTime.now().toFormat('y');

    constructor(
        private meta: Meta, private titleService: Title, private renderer: Renderer2
    ) {

        this.titleService.setTitle('Best Dermatologist Doctor in Trichy | Best Skin Clinic in Trichy');
        this.meta.addTag({
            name: 'description',
            content: 'Discover flawless skin with Trichy\'s Best Dermatologist .Expert care, personalized solutions, and a radiant you await. Book  your appointment Now'
        });
        this.meta.addTag({
            name: 'keywords',
            content: ' dermatologist in trichy, best dermatologist in trichy, best dermatologist doctor in trichy, best skin clinic in trichy,best multi speciality hospital in trichy, trichy multi speciality hospitals, multi speciality hospitals in trichy, multi speciality hospital in trichy',
        });
    }

    ngOnInit() {
        this.addCanonicalLink();
    }

    ngOnDestroy() {
        this.removeCanonicalLink();
    }

    private addCanonicalLink() {
        const link = this.renderer.createElement('link');
        this.renderer.setAttribute(link, 'rel', 'canonical');
        this.renderer.setAttribute(link, 'href', 'https://varshinihospital.com/best-dermatologist-doctor-trichy');
        this.renderer.appendChild(document.head, link);
    }

    private removeCanonicalLink() {
        const link = document.head.querySelector('link[rel="canonical"]');
        if (link) {
            this.renderer.removeChild(document.head, link);
        }
    }
}