<nav class="navbar navbar-expand-lg bg-light fixed-top">
    <div class="container-fluid">
        <a href="#" class="navbar-brand">
            <img class="img-fluid" src="./assets/img/vh%20bg-2.png">
        </a>
        <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span class="navbar-toggler-icon"><i class="fa fa-bars" aria-hidden="true"></i></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarCollapse">
            <div class="navbar-nav text-bold">
                <a href="" class="nav-item nav-link active-nav" [routerLink]="['/']">Home</a>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">About</a>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">Services</a>
                <li class="nav-item dropdown">
                    <a class="nav-link nav-item dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                       aria-expanded="false" [routerLink]="['/']">
                        Departments
                    </a>
                    <ul class="dropdown-menu">
                        <li><a [routerLink]="['/best-diabetic-hospital-trichy']"
                               class="dropdown-item text-bold text-muted" href="#">Diabetics</a></li>
                        <li><a [routerLink]="['/best-gynecologist-hospital-trichy']"
                               class="dropdown-item text-bold text-muted" href="#">Gynecologist</a></li>
                        <li><a [routerLink]="['/best-psychiatrist-hospital-trichy']"
                               class="dropdown-item text-bold text-muted"
                               href="#">Psychiatrist</a></li>
                        <li><a [routerLink]="['/best-ortho-hospital-trichy']"
                               class="dropdown-item text-bold text-muted" href="#">Orthopedic</a></li>
                        <li><a [routerLink]="['/best-dermatologist-doctor-trichy']"
                               class="dropdown-item text-bold text-muted" href="#">Dermatologist</a></li>
                        <li><a [routerLink]="['/best-physiotherapy-doctor-trichy']"
                               class="dropdown-item text-bold text-muted"
                               href="#">Physiotherapy</a></li>
                    </ul>
                </li>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">Doctors</a>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">Appointment</a>
                <a type="button" class="nav-item nav-link" [routerLink]="['/']">Contacts</a>
            </div>
        </div>
    </div>
</nav>

<div class="container-fluid">
    <div class="row mt-sm-4"></div>
    <div class="row">
        <img src="./assets/img/slider%20image/diabetes.jpg" alt="diabetics" class="img-fluid mt-5 w-100 h-auto">
    </div>

    <div class="row mt-2">
        <div class="col-lg-12 col-md-12 col-12 mt-5">
            <div class="col-lg-12 col-12">
                <h4 class="text-bold about-content">
                    At <span class="text-primary">Varshini Hospital</span> , we understand that managing diabetes
                    requires a
                    comprehensive and personalized
                    approach. Our team of skilled diabetologists, endocrinologists, and healthcare professionals is
                    dedicated to providing exceptional care tailored to each individual's unique needs. With a focus on
                    the
                    latest advancements in medical science, we offer state-of-the-art diagnostic facilities that ensure
                    accurate assessments and facilitate personalized treatment plans.
                </h4>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row mt-2">
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 15rem">
                    <div class="card-body">
                        <h3 class="text-center">Best Diabetic Hospital in Trichy</h3>
                        <p class="">Our hospital boasts a team of highly skilled endocrinologists,
                            diabetologists, and
                            diabetes care specialists dedicated to providing personalized and comprehensive care.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 15rem">
                    <div class="card-body">
                        <h3 class="text-center">Advanced Diagnostic Facilities</h3>
                        <p>Equipped with the latest diagnostic technologies, we ensure accurate
                            and timely assessments, allowing for precise treatment plans tailored to each patient's
                            unique
                            needs</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 15rem">
                    <div class="card-body">
                        <h3 class="text-center">Continuous Glucose Monitoring (CGM)</h3>
                        <p>Experience the benefits of real-time glucose monitoring with our CGM
                            systems, allowing for precise adjustments to treatment plans and enhancing overall diabetes
                            control.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 15rem">
                    <div class="card-body">
                        <h3 class="text-center">Trichy Diabetes Hospital</h3>
                        <p>Our collaborative care model involves a multidisciplinary team,
                            including nutritionists, physiotherapists, and diabetes educators, working together to
                            address all
                            aspects of diabetes management</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 15rem">
                    <div class="card-body">
                        <h3 class="text-center">Patient-Centric Care</h3>
                        <p>At the heart of our hospital is a commitment to patient satisfaction.
                            We prioritize open communication, compassionate care, and a patient-centric approach in
                            every aspect
                            of our services.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 15rem">
                    <div class="card-body">
                        <h3 class="text-center">Dedication to Prevention</h3>
                        <p class="">Proactive in our approach, we emphasize preventive measures through
                            regular health check-ups, early detection, and lifestyle modification programs.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 15rem">
                    <div class="card-body">
                        <h3 class="text-center">Community Outreach Programs</h3>
                        <p class="" style="text-align: center">Engage with our hospital's community outreach programs,
                            designed to
                            raise awareness about diabetes prevention, early detection, and management.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 15rem">
                    <div class="card-body">
                        <h3 class="text-center">Diabetes-Friendly Infrastructure</h3>
                        <p class="">Our hospital is designed to cater to the unique needs of diabetic
                            patients, with easily accessible facilities, diabetic-friendly menus, and dedicated spaces
                            for
                            education and support groups.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-md-6">
                <div class="card" style="height: 15rem">
                    <div class="card-body">
                        <h3 class="text-center">Accreditations and Recognitions</h3>
                        <p>Rest assured in the quality of our services as we proudly hold
                            accreditations and recognitions for our commitment to excellence in diabetic care.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-lg-1"></div>
        <div class="col-lg-10">
            <h3 class="text-muted mt-2 ">In choosing Trichy's premier diabetic hospital, you are selecting not
                just a medical facility but a partner in your journey to better health. Trust us to deliver
                unparalleled care and support as we stand by our commitment to managing diabetes with
                excellence.</h3>
        </div>
        <div class="col-lg-1"></div>
    </div>
</div>
<!--        <div class="col-lg-6 col-12 col-md-12">-->
<!--            <img src="./assets/img/Diabetes-amico.png" class="img-fluid animated">-->
<!--        </div>-->


<div class="container-fluid p-0 mt-0">

    <footer class="text-center" style="background-color: #b3c9ff">
        <div class="text-center text-bold text-dark p-3" style="background-color: rgba(181,215,234,0.2);">
            <span>Copyright &copy; {{ currentYear }}  </span>
            <a href="https://varnikcloud.com/"
               style="margin: 0; text-decoration: none"
               target="_blank"
            ><span class="text-dark">  VarNik Systems Services Pvt. Ltd</span></a>
            <span>. </span>
            <span>  All rights reserved.</span>
        </div>
        <div class="text-center text-bold" style="background-color: rgba(181,215,234,0.2);">
      <span class="text-black mr-2 text-end" type="button" [routerLink]="['/terms']">Terms & Conditions <span>|</span>
        <span class="text-black  text-end" type="button" [routerLink]="['/privacy']">Privacy Policy</span></span>
        </div>
    </footer>

</div>