import {Component, Renderer2} from "@angular/core";
import {DateTime} from 'luxon';
import {Meta, Title} from "@angular/platform-browser";
@Component({
    selector: 'app-psychiatrist',
    templateUrl: './psychiatrist.component.html',
    styleUrls: ['./psychiatrist.component.scss'],
})

export class PsychiatristComponent {
    public currentYear: string = DateTime.now().toFormat('y');

    constructor(
        private meta: Meta, private titleService: Title, private renderer: Renderer2
    ) {

        this.titleService.setTitle('Best Psychiatrist Hospital in Trichy | Trichy Psychiatric Hospitals');
        this.meta.addTag({
            name: 'description',
            content: 'Premier Psychiatrist Hospital in Trichy—compassionate care, expert guidance, and advanced treatment. Choose Varshini Hospital for best treatment'
        });
        this.meta.addTag({
            name: 'keywords',
            content: ' psychiatric hospital trichy, trichy psychiatric hospitals, best psychiatrist hospital in trichy, best multi speciality hospital in trichy, trichy multi speciality hospitals, multi speciality hospitals in trichy, multi speciality hospital in trichy'
        });
    }

    ngOnInit() {
        this.addCanonicalLink();
    }

    ngOnDestroy() {
        this.removeCanonicalLink();
    }

    private addCanonicalLink() {
        const link = this.renderer.createElement('link');
        this.renderer.setAttribute(link, 'rel', 'canonical');
        this.renderer.setAttribute(link, 'href', 'https://varshinihospital.com/best-psychiatrist-hospital-trichy');
        this.renderer.appendChild(document.head, link);
    }

    private removeCanonicalLink() {
        const link = document.head.querySelector('link[rel="canonical"]');
        if (link) {
            this.renderer.removeChild(document.head, link);
        }
    }
}